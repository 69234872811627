<template>
      <div class='Helpmanual'>
            <entrance :sitedata="true" @changTip="changTip"></entrance>
            <div class="main flex-column-center">
                  <div class="title flex-align-around">
                        <span class="circle circle1"></span>
                        <span>操作演示视频</span>
                        <span class="circle circle2"></span>
                  </div>
                  <div class="videoBox">
                        <div id="player"></div>
                  </div>
                  <span class="tip">点击 <span @click="centerDialog">操作手册</span> ，查看本软件操作说明文档</span>
            </div>
            <div class="dialogBox">
                  <el-dialog title="操作手册" :visible.sync="centerDialogVisible" width="65%" center top="8vh">
                        <div class="iframeDom">
                              <pdf v-for="i in numPages" :key="i" :src="pdfUrl" :page="i"></pdf>
                              <!-- <button @click="prePage">上一页</button>
            <button @click="nextPage">下一页</button>
            <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
            <div v-show="pageShow">
              <pdf :page="pageNum" :src="pdfUrl" @progress="loadedRatio = $event" @num-pages="pageTotalNum=$event">
              </pdf>
            </div> -->
                        </div>
                        <div class="spanstyle flex-align-center">
                              <span @click="centerDialogVisible =false">
                                    关闭
                              </span>
                        </div>
                  </el-dialog>
            </div>
            <!-- 帮助手册 -->
            <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
      </div>
</template>

<script>
      import entrance from '../../Common_components/head/entrance';
      import pdf from "vue-pdf";
      import subscribe from "../../Common_components/Popup/subscribe.vue";
      import {
            mapState,
            mapMutations
      } from "vuex";
      export default {
            data() {
                  return {
                        centerDialogVisible: false,
                        numPages: null,
                        pdfUrl: "",
                        player: '',
                        centerDialogVisible2: false

                  }
            },
            components: {
                  entrance,
                  pdf,
                  subscribe
            },
            //方法
            methods: {
                  //ppt
                  ...mapMutations(["hint_data"]),
                  changTip(value) {
                        if (value) {
                              this.hint_data("暂无活动");
                              this.centerDialogVisible2 = true;
                        }

                  },
                  clear(bool) {
                        this.centerDialogVisible2 = bool;

                  },
                  getNumPages() {
                        let loadingTask = pdf.createLoadingTask('/new.pdf');
                        loadingTask.promise.then(pdf => {
                              // console.log('pdf', pdf)
                              this.pdfUrl = loadingTask;
                              this.numPages = pdf.numPages
                              // this.pageTotalNum = pdf.numPages
                        }).catch(err => {
                              console.error('pdf 加载失败', err);
                        })
                  },
                  centerDialog() {
                        this.centerDialogVisible = true;
                  },
                  async loadPlayer() {
                        let params = {
                              vid: 'efe1405a67a55e1cc7825d03c69fa904_e'
                        };
                        let that = this;
                        this.$Api.Home.getVid(params).then(res => {
                              let data = res.data.playsafe;
                              this.player = new polyvPlayer({
                                    wrap: '#player',
                                    vid: 'efe1405a67a55e1cc7825d03c69fa904_e',
                                    'loop': false,
                                    'autoplay': false,
                                    // 'forceH5': true,
                                    'playsafe': data,
                                    'hideSwitchPlayer': true
                              });
                              //播放
                              that.player.on('s2j_onVideoPlay', that.attplay);
                              //暂停
                              that.player.on('s2j_onVideoPause', that.attpause);



                        }).catch(err => {

                        })
                  },

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.getNumPages();
                  this.loadPlayer();
            }
      }
</script>
<style lang='less' scoped>
      @import './less/Helpmanual';
</style>